// KozmickyStrazcaPage.js
import React from 'react'
import kozmickystrazcaImage from './static/img/kozmickystrazca.png'
import nebezpecnyImage from './static/img/nebezpecny.png'
import potrMonitorovatImage from './static/img/potr_monitorovat.png'
import kozmOdpadImage from './static/img/kozm_odpad.png'
import UKLogoImage from './static/img/UK_Logo_s_textom_TP_horizontal.png'
import spaceLabLogo from './static/img/SPACE-LAB_logotyp.png'
import telekomLogo from './static/img/T_logo_carrier_surface_rgb_n.png'
import steamWhiteLogo from './static/img/steam_white.png'
import NV22Logo from './static/img/NV22_Logo_SK.png'
import licenseImage from './static/img/license.png'
import telekomFooterLogo from './static/img/T_logo_carrier_surface_rgb_n.png'
import samplePDFSK from '../PDF/kozmicky-strazcaSK.pdf'
import samplePDFUA from '../PDF/kozmicky-strazcaUA.pdf'
import useDynamicBootstrapCSS from '../util/useDynamicBootstrapCSS'

const DebrisClanok = () => {
  useDynamicBootstrapCSS()

  return (
    <>
      <div className="container mx-auto">
        <div id="koz-str-div" className="my-3 font-weight-bold bg-secondary rounded-pill px-3">
          <h1 id="koz-str-h1" className="px-3 font-weight-bolder">
            KOZMICKÝ STRÁŽCA
          </h1>
        </div>

        <h1 id="mon-koz-odp" className="my-3 text-secondary font-weight-lighter">
          MONITOROVANIE <br /> KOZMICKÉHO ODPADU
        </h1>

        <div className="row pt-3">
          <div className="col h3 font-weight-lighter text-dark">
            Úlohou kozmického strážcu je chrániť našu Zem a vesmírne technológie pred ich poškodením. Satelity na obežnej dráhe
            Zeme môžu byť zasiahnuté časťami nefunkčných satelitov - kozmickým odpadom.
          </div>
          <div className="col text-justify">
            <div>
              Keď boli tvoji starí rodičia v tvojom veku, kozmický strážca by nemal veľa práce, pretože okolo Zeme obiehalo len
              niekoľko funkčných satelitov. Dnes ich je okolo 5 000. Problémom však je, že je tam tiež viac ako 30 000 kusov
              nebezpečného kozmického odpadu. Tento odpad je potrebné monitorovať a následne upravovať pozície aktívnych
              satelitov, aby sa predišlo nežiadúcim kolíziam.
            </div>
            <div>
              <a
                href="/kozmickyodpad/app"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-lg btn-outline-success rounded-pill my-4"
              >
                Vyskúšaj našu appku!
              </a>
            </div>
          </div>
        </div>

        <img src={kozmickystrazcaImage} className="img-fluid mx-auto d-block my-5 my_img" alt="Kozmicky Strazca" />

        <div className="row my-5 px-3">
          <div className="col h1 text-right font-weight-bold text-secondary">Čo je kozmický odpad?</div>
          <div className="col my-auto">
            <ul>
              <li>nefunkčné satelity,</li>
              <li>nosné rakety,</li>
              <li>úlomky kozmických objektov.</li>
            </ul>
          </div>
        </div>

        <div className="row my-5 px-3">
          <div className="col my-auto text-right">
            <img src={nebezpecnyImage} height="200em" className="my_img" alt="Nebezpecny" />
          </div>
          <div className="col my-auto">
            <div className="mt-1 h2 font-weight-bold text-secondary"> Prečo je nebezpečný?</div>
            <div>
              Rýchlosť kozmického odpadu je ~ 28 000 km/h, teda približne 500-krát rýchlejšie ako rýchlosť auta. Predstav si
              zrážku v tejto rýchlosti.
            </div>
          </div>
        </div>

        <div className="row my-5 px-3">
          <div className="col h1 text-right font-weight-bold text-secondary">Koľko odpadu je v kozme?</div>
          <div className="col my-auto">
            <ul>
              <li>viac ako 36 000 objektov s priemerom {'>'}10 cm,</li>
              <li>viac ako 1 000 000 objektov s priemerom 1-10 cm</li>
              <li>viac ako 130 000 000 objektov s priemerom 0.1 - 1 cm</li>
              <li>Celková váha kozmického odpadu sa odhaduje na 10 100 ton. Toľko váži napríklad Eiffelova veža v Paríži.</li>
            </ul>
          </div>
        </div>

        <div className="row my-5 px-3">
          <div className="col text-right my-auto">
            <div className="mt-1 h2 font-weight-bold text-secondary">Je potrebné ho monitorovať?</div>
            <div className="text-right">
              Áno. Je to zatiaľ jediná možnosť ako predísť zrážke. Ak budeme vedieť, kde sa nebezpečný kozmický odpad nachádza,
              budeme môcť dať povel funkčnému satelitu, aby vykonal uhýbací manéver.
            </div>
          </div>
          <div className="col my-auto">
            <img src={potrMonitorovatImage} height="200em" className="my_img" alt="Potrebne Monitorovat" />
          </div>
        </div>

        <div className="row my-5 px-3">
          <div className="col my-auto text-right">
            <img src={kozmOdpadImage} height="200em" className="my_img" alt="Kozmicky Odpad" />
          </div>
          <div className="col my-auto">
            <div className="mt-1 h2 font-weight-bold text-secondary">Ako sa zbaviť kozmického odpadu?</div>
            <ul>
              <li>Ako pri každom odpade, najmä sa ho snažiť nevytvárať.</li>
              <li>V najbližších rokoch sa chystajú vesmírne misie, ktoré budú kozmický odpad aktívne odstraňovať.</li>
            </ul>
          </div>
        </div>

        {/* Additional informative text and links */}
        <div className="my-5 mx-5">
          <p>
            Monitorovaniu kozmického odpadu sa venujú experti aj na Slovensku. Astronómovia na{' '}
            <a href="https://www.matfyzjein.sk/" target="_blank">
              MatFyze
            </a>{' '}
            (Fakulte matematiky, fyziky a informatiky, Univerzity Komenského v Bratislave) pozorujú kozmický odpad pomocou svojich
            ďalekohľadov. Aj vďaka týmto meraniam môžu satelity, ktoré používaš každý deň (navigácia, predpoveď počasia, mapy,
            satelitná televízia …), fungovať bezpečnejšie a dlhodobejšie.
          </p>
          <p>
            Viac o kozmickom odpade sa dozvieš v prednáškach:{' '}
            <a href="https://youtu.be/hiQ3AtAEM8A" target="_blank" rel="noopener noreferrer">
              SPACE::TALK #06 - Kozmický odpad a jeho výskum na Slovensku
            </a>
          </p>
          <p>
            Opakom kozmického odpadu sú funkčné prístroje, vďaka ktorým môžeme lepšie rozumieť vesmíru. Vedel si, že v Košiciach
            bolo vyrobených už viac ako 20 takýchto vedeckých prístrojov pre vesmírne satelity? Pri ich konštrukcii v{' '}
            <a href="http://www.space-lab.sk/" target="_blank">
              SPACE::LABe
            </a>{' '}
            (na Oddelení kozmickej fyziky, Ústav experimentálnej fyziky SAV, v. v. i.) museli byť dodržané vysoké štandardy a
            precízne testovanie. Oprava prístrojov vo vesmíre totiž zvyčajne už nie je možná.
          </p>

          <div className="my-5">
            <div className="my-2 h2 font-weight-bold text-secondary">Kozmický Strážca - Kozmický odpad PDF verzia</div>
            <a href={samplePDFSK} target="_blank" rel="noopener noreferrer" className="btn btn-outline-secondary m-2">
              Brožúra na stiahnutie
              <br />
              (SK verzia)
            </a>
            <a href={samplePDFUA} target="_blank" rel="noopener noreferrer" className="btn btn-outline-secondary m-2">
              Brožúra na stiahnutie
              <br />
              (UA verzia)
            </a>
          </div>

          <div className="my-5">
            <div className="mt-1 h2 font-weight-bold text-secondary">Užitočné linky:</div>
            <ul>
              <li>
                ESA - kozmicky odpad:
                <a href="https://www.esa.int/Space_Safety/Space_Debris/" target="_blank" rel="noopener noreferrer">
                  https://www.esa.int/Space_Safety/Space_Debris/
                </a>
              </li>
              <li>
                Štatistiky kozmického prostredia:{' '}
                <a href="https://sdup.esoc.esa.int/discosweb/statistics/" target="_blank" rel="noopener noreferrer">
                  https://sdup.esoc.esa.int/discosweb/statistics/
                </a>
              </li>
            </ul>
          </div>

          <div className="my-5">
            <div className="mt-1 h2 font-weight-bold text-secondary">Credits</div>
            <div className="d-flex flex-wrap">
              <a href="https://uniba.sk" target="_blank" rel="noopener noreferrer" className="mx-2">
                <img src={UKLogoImage} height="60em" style={{ filter: 'brightness(0%)' }} alt="Uniba" />
              </a>
              <a href="http://www.space-lab.sk/" target="_blank" rel="noopener noreferrer" className="mx-2 align-self-center">
                <img src={spaceLabLogo} height="32em" alt="Space Lab" />
              </a>
              <a href="https://www.deutschetelekomitsolutions.sk/" target="_blank" rel="noopener noreferrer" className="mx-2">
                <img src={telekomLogo} height="60em" alt="Deutsche Telekom IT Solutions" />
              </a>
              <a href="https://www.innovlab.sk" target="_blank" rel="noopener noreferrer" className="mx-2">
                <img src={steamWhiteLogo} height="70em" alt="Innovlab" />
              </a>
              <a
                href="https://www.nocvyskumnikov.sk"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2 align-self-center"
              >
                <img src={NV22Logo} height="40em" alt="Noc Vyskumnikov" />
              </a>
            </div>
          </div>

          <div className="my-5">
            <h5 className="mt-1 h2 font-weight-bold text-secondary">Licencia</h5>
            <div className="mt-2">
              <p>
                Ak nie je uvedené inak, na obsah tohto dokumentu, aplikácie a zdrojového kódu sa vzťahuje licencia{' '}
                <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">
                  Commons Attribution-NonCommercial-ShareAlike 4.0 International (CC BY-NC-SA 4.0)
                </a>
              </p>
              <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noopener noreferrer">
                <img src={licenseImage} height="75em" style={{ filter: 'brightness(0%)' }} alt="License" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className="text-center p-3">
          <span className="text-muted">
            <a href="https://www.deutschetelekomitsolutions.sk/" target="_blank" rel="noopener noreferrer">
              <img src={telekomFooterLogo} height="20em" alt="Deutsche Telekom IT Solutions Logo" />
            </a>{' '}
            © 2022 Deutsche Telekom IT Solutions
          </span>
          <br />
          <a href="https://www.deutschetelekomitsolutions.sk/" target="_blank" rel="noopener noreferrer">
            https://www.deutschetelekomitsolutions.sk/
          </a>
        </div>
      </footer>
    </>
  )
}

export default DebrisClanok
