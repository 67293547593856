// authSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { urlLogin } from '../util/States'

export const checkLoggedIn = createAsyncThunk('auth/checkLoggedInStatus', async (_, { rejectWithValue }) => {
  try {
    const response = await fetch(urlLogin + 'user', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const result = await response.json()
    if (response.ok) {
      // If the response is OK, return the whole result object
      return result
    } else {
      // If there's an error, handle it by returning or throwing an error
      throw new Error('Not logged in')
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

const authSlice = createSlice({
  name: 'user',
  initialState: {
    isLoggedIn: false,
    data: {}, // The user data will be stored here
  },
  reducers: {
    // Add a reducer to clear the user data on logout
    logout: (state) => {
      state.isLoggedIn = false
      state.data = {}
    },
  },
  extraReducers: {
    [checkLoggedIn.fulfilled]: (state, action) => {
      state.isLoggedIn = action.payload.isLogged
      state.data = action.payload.data || {} // Store the user data if present
    },
    [checkLoggedIn.rejected]: (state, action) => {
      state.isLoggedIn = false
      state.data = {}
    },
  },
})

// Export the logout action
export const { logout } = authSlice.actions

export default authSlice.reducer
