import './App.css'
import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Provider } from 'react-redux'
import store from './redux/store'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/sk'
import utcPlugin from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import SplashScreen from './pages/SplashScreen'
import LogIn from './pages/LogIn'
import SignUp from './pages/SignUp'
import NoRights from './pages/NoRights'
import Home from './pages/Home'
import PrivateRoute from './components/PrivateRoute'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { moduleNames } from './util/States.js'
import SatelliteHome from './pages/SatelliteHome.js'
import { interpolateCatmullRomSpline } from './util/helpersForOptimizedOrbits.js'
import Kozmickystrazca from './pages/KozmickyStrazca.js'
import DebrisClanok from './pages/DebrisClanok.js'
import PlanetaryClanok from './pages/PlanetaryClanok.js'
import WeatherHome from './pages/WeatherHome.js'

const Viewer = React.lazy(() => import('./pages/Viewer.js'))
const AsteroidHome = React.lazy(() => import('./pages/AsteroidHome.js'))

// Import the plugins
dayjs.extend(utc)
dayjs.extend(timezone)
library.add(fas)
dayjs.extend(utcPlugin)

export default function App() {
  const [isLoadingAsteroidData, setIsLoadingAsteroidData] = useState(true)
  const [asteroidData, setAsteroidData] = useState([])

  //loading all asteroids expect 15top
  useEffect(() => {
    setIsLoadingAsteroidData(true)
    fetch('https://kozmickystrazca.sk/api2/asteroids/mongo/asteroids')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        let newaste = []
        for (let asteroid of data) {
          newaste.push({ ...asteroid, coords: interpolateCatmullRomSpline(asteroid.coords) })
        }
        setAsteroidData(newaste)
        setIsLoadingAsteroidData(false)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setIsLoadingAsteroidData(false)
      })
  }, [])
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'sk'}>
        <Provider store={store}>
          <Router basename="/">
            <Routes>
              <Route
                path="/planetarnaobrana/"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <AsteroidHome />
                  </Suspense>
                }
              />
              <Route
                path="/kozmickyodpad/"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <SatelliteHome />
                  </Suspense>
                }
              />
              <Route
                path="/vesmirnepocasie/"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <WeatherHome />
                  </Suspense>
                }
              />
              <Route
                path="/vesmirnepocasie/app"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <Viewer defaultModule={moduleNames.weather} disableOtherModules={true} />
                  </Suspense>
                }
              />
              <Route
                path="/planetarnaobrana/app"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <Viewer
                      asteroidData={asteroidData}
                      isLoadingAsteroidData={isLoadingAsteroidData}
                      defaultModule={moduleNames.asteroid}
                      disableOtherModules={true}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/kozmickyodpad/app"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <Viewer defaultModule={moduleNames.satellite} disableOtherModules={true} />
                  </Suspense>
                }
              />
              <Route
                path="/"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <Kozmickystrazca />
                  </Suspense>
                }
              />
              <Route
                path="/debrisClanok"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <DebrisClanok />
                  </Suspense>
                }
              />
              <Route
                path="/planetaryClanok"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <PlanetaryClanok />
                  </Suspense>
                }
              />
              <Route
                path="/toolbox"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <Viewer
                      toolbox
                      asteroidData={asteroidData}
                      isLoadingAsteroidData={isLoadingAsteroidData}
                      defaultModule={moduleNames.asteroid}
                      disableOtherModules={false}
                    />
                  </Suspense>
                }
              />

              <Route path="/signup" element={<PrivateRoute admin componentToRender={<SignUp />} />} />
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<LogIn />} />
              <Route path="/norights" element={<NoRights />} />
            </Routes>
          </Router>
        </Provider>
      </LocalizationProvider>
    </>
  )
}
