import { useEffect } from 'react'

const useDynamicBootstrapCSS = () => {
  useEffect(() => {
    const bootstrapCSS = document.createElement('link')
    bootstrapCSS.href = 'https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css'
    bootstrapCSS.rel = 'stylesheet'
    bootstrapCSS.integrity = 'sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T'
    bootstrapCSS.crossOrigin = 'anonymous'
    document.head.appendChild(bootstrapCSS)

    const additionalCSS = document.createElement('link')
    additionalCSS.href = '/css/styleNew.css' // Update path as needed
    additionalCSS.rel = 'stylesheet'
    document.head.appendChild(additionalCSS)

    const additionalCSS2 = document.createElement('link')
    additionalCSS2.href = '/css/style.css' // Update path as needed
    additionalCSS2.rel = 'stylesheet'
    document.head.appendChild(additionalCSS2)

    return () => {
      document.head.removeChild(bootstrapCSS)
      document.head.removeChild(additionalCSS)
      document.head.removeChild(additionalCSS2)
    }
  }, [])
}

export default useDynamicBootstrapCSS
