import * as THREE from 'three'

export const basename = ''

export const urlLogin = 'https://www.kozmickystrazca.sk/scpauth/'

export const moduleNames = {
  collision: 'Collisions prevention',
  satellite: 'Debris & Satellites monitoring',
  asteroid: 'Planetary defense',
  experiment: 'Experimental Case Study',
  weather: 'Space weather',
}

export const scaleFactor = 0.0000002

export const sounds = {
  chill: new Audio(basename + '/music/space-chill.mp3'),
  extreme: new Audio(basename + '/music/space-age.mp3'),
  boom: new Audio(basename + '/music/boom.wav'),
}

export const cameraPositions = [
  {
    start: {
      camera: new THREE.Vector3(18.92327541362096, 5.280578404829839, -8.15762610127381),
      target: [22.026919541623293, 1.7794744262934192, 2.113369066086226],
    },
    end: {
      camera: new THREE.Vector3(-67.23663346670216, -67.46026483556204, -37.629108105695295),
      target: [-67.58553032172956, -66.33839462766869, -36.46115379947103],
    },
  },
  {
    start: {
      camera: new THREE.Vector3(8.255538032866907, 16.080363379809896, -3.8773495193796643),
      target: [8.397637149622478, 12.193017316070462, 7.0750588724656795],
    },
    end: {
      camera: new THREE.Vector3(-52.0452563770267, -70.75492378111164, -41.06064821787308),
      target: [-64.88012370290056, -34.218826593759566, 21.603559694993027],
    },
  },
  {
    start: {
      camera: new THREE.Vector3(2.3718555031719464, 27.697119292869658, 0.5446275630077277),
      target: [-1.327305929136211, 15.571844340131992, 15.2456530041787],
    },
    end: {
      camera: new THREE.Vector3(-36.66402721222869, -69.71985987641335, -44.73384949608596),
      target: [-5.984778167736066, -1.1802942464301827, 5.670268115587155],
    },
  },
  {
    start: {
      camera: new THREE.Vector3(-14.310419753843231, 23.465850453401885, -2.967627701116932),
      target: [-10.260581086843061, 12.528947225668276, 16.438753296004034],
    },
    end: {
      camera: new THREE.Vector3(-27.035006595432744, -57.907845189474564, -36.636225981952364),
      target: [8.885575849199044e-305, -1.820291494322521e-304, 2.0210126644874374e-304],
    },
  },
  {
    start: {
      camera: new THREE.Vector3(-28.399995429939384, 19.084504659566054, -2.587214101998276),
      target: [-24.998364740376267, 10.056867168612838, 13.129539662046376],
    },
    end: {
      camera: new THREE.Vector3(-20.252348161888882, -34.95871803199569, -22.87320285284115),
      target: [0, 0, 0],
    },
  },
  {
    start: {
      camera: new THREE.Vector3(-29.817383672712033, 4.471148174351363, -6.209026893939364),
      target: [-29.838861966075786, 3.524346822644025, 5.375014155855557],
    },
    end: {
      camera: new THREE.Vector3(-28.101727161768252, -8.125445808260274, -7.583679953179945),
      target: [-23.043295484027954, -4.238084255772867, 2.131647642011955],
    },
  },
]

export const planetSizes = {
  Mercury: 4879,
  Venus: 12104,
  Earth: 12742,
  Mars: 6779,
  Jupiter: 139822,
  Saturn: 116464,
  Uranus: 50724,
  Neptune: 49244,
  Moon: 3000,
}

export const planetZoomExperiment = {
  Mercury: 1.08,
  Venus: 1.1,
  Earth: 1.08,
  Mars: 1.03,
  Jupiter: 1.2,
  Saturn: 1.08,
  Uranus: 1.02,
  Neptune: 1.01,
  Moon: 3000,
}

export const planetZoom = {
  Mercury: 1.08,
  Venus: 2,
  Earth: 3,
  Mars: 1.12,
  Jupiter: 25,
  Saturn: 22,
  Uranus: 10,
  Neptune: 10,
  Moon: 3000,
}
