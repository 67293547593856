import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { moduleNames, urlLogin } from '../util/States'

// Async thunk for creating or updating preferences on the server
export const updateUserPreferences = createAsyncThunk(
  'preferences/updateUserPreferences',
  async ({ userId, preferences }, { rejectWithValue }) => {
    try {
      const response = await fetch(urlLogin + `user/${userId}/preferences`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ preferences }),
      })

      if (!response.ok) {
        throw new Error('Failed to update preferences')
      }

      const updatedPreferences = await response.json()
      return updatedPreferences
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const fetchUserPreferences = createAsyncThunk('preferences/fetchUserPreferences', async (userId, { rejectWithValue }) => {
  try {
    const response = await fetch(urlLogin + `user/${userId}/preferences`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        // Include any necessary authentication headers here
      },
    })

    if (!response.ok) {
      throw new Error('Failed to fetch preferences')
    }

    const preferences = await response.json()
    return preferences.data
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: {
    general: null,
    planetaryDefense: null,
    debrisSatelliteMonitoring: null,
    collisionsPrevention: null,
    experimentalCaseStudy: null,
    loading: false,
    error: null,
    fetchedPreferences: null,
    selectedImportedData: null,
  },
  reducers: {
    resetPreferencesState: (state) => {
      // This sets the state back to its initial state
      return preferencesSlice.getInitialState()
    },
    setSelectedImportedData: (state, action) => {
      console.log(action.payload)
      state.selectedImportedData = action.payload
    },
    setPreferencesState: (state, action) => {
      switch (action.payload.module) {
        case moduleNames.asteroid:
          state.planetaryDefense = action.payload.data
          break
        case moduleNames.collision:
          state.collisionsPrevention = action.payload.data
          break
        case moduleNames.satellite:
          state.debrisSatelliteMonitoring = action.payload.data
          break
        case moduleNames.experiment:
          state.experimentalCaseStudy = action.payload.data
          break
        case 'general':
          state.general = action.payload.data
          break
        default:
          console.error('nothing do in slicepreferences')
      }
    },
  },
  extraReducers: {
    // Handle pending state
    [updateUserPreferences.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    // Handle rejected state
    [updateUserPreferences.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload || 'Failed to update preferences'
    },
    [fetchUserPreferences.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [updateUserPreferences.fulfilled]: (state, action) => {
      // // Check if the payload contains the preferences object and only then try to access its properties
      // const { preferences } = action.payload // This is where you expect the preferences object
      // state.general = preferences?.general ?? state.general
      // state.planetaryDefense = preferences?.planetaryDefense ?? state.planetaryDefense
      // state.debrisSatelliteMonitoring = preferences?.debrisSatelliteMonitoring ?? state.debrisSatelliteMonitoring
      // state.collisionsPrevention = preferences?.collisionsPrevention ?? state.collisionsPrevention
      state.loading = false
    },
    [fetchUserPreferences.fulfilled]: (state, action) => {
      // Merge the fetched preferences into the existing state
      state.fetchedPreferences = action.payload
      state.loading = false
      // [fetchUserPreferences.fulfilled]: (state, action) => {
      //   // Merge the fetched preferences into the existing state
      //   state.general = action.payload.general || state.general
      //   state.planetaryDefense = action.payload.planetaryDefense || state.planetaryDefense
      //   state.debrisSatelliteMonitoring = action.payload.debrisSatelliteMonitoring || state.debrisSatelliteMonitoring
      //   state.collisionsPrevention = action.payload.collisionsPrevention || state.collisionsPrevention
      //   state.loading = false
    },
    [fetchUserPreferences.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload || 'Failed to fetch preferences'
    },
  },
})
export const { resetPreferencesState, setPreferencesState, setSelectedImportedData } = preferencesSlice.actions

export default preferencesSlice.reducer
