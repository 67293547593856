import { Link, useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { increment, decrement } from '../redux/slice'
import './Header.css'
import { basename, urlLogin } from '../util/States'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { checkLoggedIn } from '../redux/isLoggedIn'
import { resetPreferencesState } from '../redux/preferencesSlice'

export default function Header(props) {
  const { windowWidth } = props
  const navigate = useNavigate()

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkLoggedIn())
  }, [dispatch])

  const [isAdmin, setIsAdmin] = useState(null)
  useEffect(() => {
    const checkIsAdmin = async () => {
      try {
        const response = await fetch(urlLogin + 'admin', {
          method: 'get',
          credentials: 'include',
          headers: {
            // needed so express parser says OK to read
            'Content-Type': 'application/json',
          },
        })
        const result = await response.json()
        if (result.isAdmin) {
          setIsAdmin(true)
        } else {
          setIsAdmin(false)
        }
      } catch (error) {
        console.error('Error checking login status: ', error)
        setIsAdmin(false)
      }
    }

    checkIsAdmin()
  }, [])

  const logout = async () => {
    const response = await fetch(urlLogin + 'logout', {
      method: 'get',
      credentials: 'include',
      headers: {
        // needed so express parser says OK to read
        'Content-Type': 'application/json',
      },
    })
    const result = await response.json()
    if (result.data) {
    }
    dispatch(resetPreferencesState())

    navigate('/home')
  }

  const [click, setClick] = useState(false)
  const [button, setButton] = useState(true)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const showButton = () => {
    if (windowWidth <= 700) {
      setButton(false)
    } else {
      setButton(true)
    }
  }

  useEffect(() => {
    showButton()
  }, [windowWidth])

  return (
    <>
      <div className="header" style={{ backgroundColor: click && !button && 'black' }}>
        <div
          className="header-logo"
          onClick={() => {
            navigate('/home')
          }}
        >
          <img className="header-logo-image" alt="orbitCollision" src={basename + '/images/satellite_svg.png'} />
          <div className="header-logo-text">Space Wardens</div>
        </div>
        {button ? (
          <div className="header-container">
            <div
              className="header-button"
              onClick={() => {
                navigate('/home')
              }}
            >
              Home
            </div>
            {/* <span>
        <button onClick={() => dispatch(increment())}>Increment</button>
          {counter}
        </span> */}
            {!isLoggedIn && (
              <div
                className="header-button"
                onClick={() => {
                  navigate('/login')
                }}
              >
                Log in
              </div>
            )}
            <div
              className="header-button"
              onClick={() => {
                navigate('/toolbox')
              }}
            >
              App
            </div>
            {isAdmin && (
              <div
                className="header-button"
                onClick={() => {
                  navigate('/signup')
                }}
              >
                Register
              </div>
            )}
          </div>
        ) : (
          <div className="menu-icon" onClick={handleClick}>
            <FontAwesomeIcon icon={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
        )}
        {!button && (
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <Link
                to={'/home'}
                className="nav-links"
                onClick={() => {
                  closeMobileMenu()
                }}
              >
                Home
              </Link>
            </li>
            {!isLoggedIn && (
              <li className="nav-item">
                <Link
                  to={'/login'}
                  className="nav-links"
                  onClick={() => {
                    closeMobileMenu()
                  }}
                >
                  Log in
                </Link>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <Link
                  to={'/signup'}
                  className="nav-links"
                  onClick={() => {
                    closeMobileMenu()
                  }}
                >
                  Register
                </Link>
              </li>
            )}
            {isLoggedIn && (
              <li className="nav-item">
                <Link
                  to={'/home'}
                  className="nav-links"
                  onClick={() => {
                    logout()
                    window.location.reload()
                  }}
                >
                  Logout
                </Link>
              </li>
            )}
            <li>
              <Link
                to={'/toolbox'}
                className="nav-links-mobile"
                onClick={() => {
                  closeMobileMenu()
                }}
              >
                START APP
              </Link>
            </li>
          </ul>
        )}
      </div>
      <div className="header-logout">
        {isLoggedIn && (
          <div
            className="header-button"
            onClick={() => {
              logout()
              window.location.reload()
            }}
          >
            Logout
          </div>
        )}
      </div>
    </>
  )
}
