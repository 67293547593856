import { createSlice } from '@reduxjs/toolkit'

const statesSlice = createSlice({
  name: 'states',
  initialState: { responsiveWindow: false },
  reducers: {
    setResponsiveWindow: (state, action) => {
      state.responsiveWindow = action.payload
    },
    decrement: (state) => {
      state.value -= 1
    },
  },
})

export const { setResponsiveWindow } = statesSlice.actions
export default statesSlice.reducer
