export function dateToIndex(date, listLength, startDate = new Date('2022-12-31T00:00:00')) {
  const jsDate = date.toDate()
  const timeDiff = jsDate.getTime() - startDate.getTime()
  let diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
  if (diffDays < 0) {
    diffDays = listLength - (Math.abs(diffDays) % listLength)
  } else {
    diffDays = diffDays % listLength
  }
  return diffDays
}
export function generateIndexes(startDate, endDate, listLength) {
  const indexes = []

  // Convert dayjs to native JS Date for startDate and endDate
  const startTime = startDate.toDate().getTime()
  const endTime = endDate.toDate().getTime()

  const startDayIndex = dateToIndex(startDate, listLength)
  const timeDiff = endTime - startTime
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))

  for (let i = 0; i <= daysDiff; i++) {
    const currentIndex = (startDayIndex + i) % listLength
    indexes.push(currentIndex)
  }

  return indexes
}

// Catmull-Rom spline kiszámítása
function catmullRomSpline(p0, p1, p2, p3, t) {
  const v0 = (p2 - p0) / 2
  const v1 = (p3 - p1) / 2
  const t2 = t * t
  const t3 = t2 * t
  const c0 = 2 * p1 - 2 * p2 + v0 + v1
  const c1 = -3 * p1 + 3 * p2 - 2 * v0 - v1
  const c2 = v0
  const c3 = p1

  return c0 * t3 + c1 * t2 + c2 * t + c3
}

// Catmull-Rom spline alkalmazása az adatokra
export function interpolateCatmullRomSpline(data) {
  const newPoints = []
  for (let i = 0; i < data.length; i++) {
    const p0 = i > 0 ? data[i - 1] : data[data.length - 1]
    const p1 = data[i]
    const p2 = data[(i + 1) % data.length]
    const p3 = data[(i + 2) % data.length]

    // Add the original point
    newPoints.push(p1)

    // Add a point between this and the next point
    const t = 0.5
    const x = catmullRomSpline(p0[0], p1[0], p2[0], p3[0], t)
    const y = catmullRomSpline(p0[1], p1[1], p2[1], p3[1], t)
    const z = catmullRomSpline(p0[2], p1[2], p2[2], p3[2], t)
    newPoints.push([x, y, z])
  }
  return newPoints
}
