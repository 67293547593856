import { createSlice } from '@reduxjs/toolkit'

const longLatSlice = createSlice({
  name: 'longLat',
  initialState: { longitude: null, latitude: null, altitude: null, change: false, actualCameraLLA: null },
  reducers: {
    setLatitude: (state, action) => {
      state.latitude = action.payload
    },
    setLongtitude: (state, action) => {
      state.longitude = action.payload
    },
    setAltitude: (state, action) => {
      state.altitude = action.payload
    },
    setChange: (state) => {
      state.change = !state.change
    },
    setActualCameraLLA: (state, action) => {
      state.actualCameraLLA = action.payload
    },
  },
})

export const { setLatitude, setLongtitude, setAltitude, setChange, setActualCameraLLA } = longLatSlice.actions
export default longLatSlice.reducer
