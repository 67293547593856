import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'Chybné súradnice': 'Incorrect coordinates',
      Hľadaj: 'Search',
      'Zemepisná dĺžka': 'Longitude',
      'Zemepisná šírka': 'Latitude',
      Nájsť: 'Find',
      Názov: 'Name',
      DÁTUMY: 'DATES',
      'Vesmírne počasie': 'Space weather',
      'Dátum vytvorenia': 'Date created',
      'Uložiť nastavenia': 'Save settings',
      'Uložiť nastavenia?': 'Save settings?',
      'Názov nastavenia': 'Name of setting',
      Uložiť: 'Save',
      Importovať: 'Import',
      'Uložené nastavenia': 'Saved settings',
      'Načítavam všetky asteroidy': 'Loading all asteroids',
      'Planetary defense': 'Planetarna obrana',
      SPUSTIŤ: 'LAUNCH',
      'ČÍTAJ VIAC': 'READ MORE',
      FILTER: 'FILTER',
      'Výber objektu:': 'Choose object:',
      SATELIT: 'SATELLITE',
      ODPAD: 'DEBRIS',
      Satelit: 'Satellite',
      'Planetary defense': 'Planetarna obrana',
      Odpad: 'Debris',
      Domov: 'Home',
      'Počet objektov': 'Number of objects',
      'Vesmírny odpad': 'Space debris',
      Satelity: 'Satellites',
      Hrozby: 'Threats',
      'Info o App': 'About',
      DOMOV: 'HOME',
      Moduly: 'Moduls',
      'Výber orbity:': 'Choose orbit:',
      Nízka: 'Low',
      Stredná: 'Middle',
      Vysoká: 'High',
      'Geostac.': 'Geostat.',
      Jazyk: 'Language',
      APOGEUM: 'APOGEE',
      PERIGEUM: ' PERIGEE',
      VLASTNÍK: 'OWNER',
      TYP: 'TYPE',
      NÁZOV: 'NAME',
      Vzdialenosť: 'Distance',
      'ZEMEPISNÁ ŠÍRKA': 'LATITUDE',
      'ZEMEPISNÁ DĹŽKA': 'LONGITUDE',
      'KOZMICKÝ STRÁŽCA': 'SPACE GUARDIAN',
      'Monitorovanie kozmického odpadu': 'Space debris monitoring',
      'Prevencia kolízií': 'Collision prevention',
      Kolízia: 'Collision',
      Výber: 'Selected',
      'Kozmický Strážca - Kozmický odpad PDF verzia': 'Space Guardian - Space Debris PDF version',
      'Ak nie je uvedené inak, na obsah tohto dokumentu, aplikácie a zdrojového kódu sa vzťahuje licencia':
        'Unless otherwise noted, the contents of this document, app andsource code are licensed under a license',
      Optimalizácia: 'Optimalization',
      Legenda: 'Legend',
      Štatistika: 'Stats',
      Manéver: 'Maneuver',
      'Menej objektov': 'Less objects',
      '25% nízka orbita, 50% ostatné': '25% low orbit, 50% other',
      'Vypnúť animáciu': 'Turn off animation',
      'Filter: 25% nízka orbita, 50% ostatné': 'Filter: 25% low orbit, 50% other',
      'Planetárna obrana': 'Planetary defense',
      'Experimentálna Prípadová Štúdia': 'Experimental Case Study',
      'VYBER FILTRE': 'SELECT FILTERS',
      FILTRE: 'FILTERS',
      'Vyber objekty:': 'Choose objects:',
      Planéty: 'Planets',
      'Všetky asteroidy': 'All asteroids',
      'Zem a Slnko': 'Earth and Sun',
      'Zobraziť nastavenia': 'Display settings',
      Nastavenia: 'Settings',
      'Planetárne orbity': 'Planet orbits',
      Ovládanie: 'Controls',
      Verzia: 'Version',
      Začiatok: 'Start date',
      Koniec: 'End date',
      'Aktuálny deň': 'Current date',
      '1 krok': 'Slider steps',
      rok: 'year',
      mesiac: 'month',
      týždeň: 'week',
      deň: 'day',
      krok: 'step',
      'Vitaj v': 'Welcome to',
      'Experimentálnej Prípadovej Štúdii': 'Experimental Case Study',
      Merkúr: 'Mercury',
      Venuša: 'Venus',
      Zem: 'Earth',
      Mars: 'Mars',
      Jupiter: 'Jupiter',
      Saturn: 'Saturn',
      Urán: 'Uranus',
      Neptán: 'Neptune',
      Neznámy: 'Unknown',
      Vlastnosti: 'Properties',
      Atribúty: 'Vlastnosti',
      'Orbitálna dráha': 'Orbital stats',
      'Ostatné vlastnosti': 'Other attributes',
      Hmotnosť: 'Mass',
      'Množstvo hmoty planéty': 'Amount of matter planet contains',
      'Rovníkový polomer': 'Equatorial radius',
      Objem: 'Volume',
      Gravitácia: 'Gravity',
      'Priemerná hustota': 'Mean density',
      Perihelium: 'Perihelion',
      Afélium: 'Aphelion',
      Sklon: 'Inclination',
      Excentricita: 'Eccentricity',
      'Stredná obežná rýchlosť': 'Mean orbital velocity',
      'Dĺžka dňa': 'Length of day',
      'Doba rotácie': 'Period of rotation',
      'Počet prirodzených satelitov': 'Number of natural satellites',
      'Vzdialenosť polomeru': 'Distance of eq. radius',
      'Táto planéta zaberá': 'This planet takes up',
      'Gravitačná sila na tejto planéte': 'Gravity force on this planet',
      'Priemerná hmotnosť na': 'Average mass per',
      'Najbližšia vzdialenosť od slnka': 'Closest distance to sun',
      'Najvzdialenejšia vzdialenosť od slnka': 'Farthest distance to sun',
      'Sklon obežnej dráhy': "Tilt of planet's orbit",
      Okružnosť: 'Circularity measure',
      'Priemerná rýchlosť na obežnej dráhe': 'Average speed in orbit',
      'Trvanie jednej rotácie okolo osi': 'Duration of one rotation on its axis',
      hodín: 'hours',
      Späť: 'Back',
      Naspäť: 'Previous',
      Ďalej: 'Next',
      pozeráš: 'viewing',
      'Žiadne dáta': 'No data present',
      'VRÁŤ SA SPÄŤ': 'RETURN BACK',
      Bezmenný: 'Unnamed',
      'Základné štatistiky': 'Essential stats',
      'Ďalšie info': 'Further info',
      'Prvé pozorovanie': 'First observation',
      'Posledné pozorovanie': 'Last observation',
      'Vzdialenosť semilatus rectum': 'Semilatus rectum distance',
      Magnitúda: 'Magnitude',
      'Synodická perióda': 'Synodic period',
      'Orbitálne obdobie': 'Orbital period',
      Pozorovania: 'Observations',
      Opozície: 'Oppositions',
      'Absolútna magnitúda': 'Absolute magnitude',
      'Čas do úplného otočenia okolo Slnka': 'Time to complete rotation around sun',
      'Odchýlka od kruhovej dráhy': 'Deviation from circular orbit',
      'Uhol vzhľadom na rovinu ekliptiky x-y': 'Angle relative to the x-y ecliptic plane',
      'Počet pozorovaní': 'Number of times observed',
      'Počet opozícií': 'Number of oppositions',
      'Zahájiť obrannú misiu': 'Start defense mission',
      rokov: 'years',
      'Naša Zem je ten najvzáčnejší vesmírny objekt a je potrebné ju neustále chrániť pred rizikom zrážky s asteroidom, no dnešné technológie nám umožňujú včasné predchádzať tejto hrozbe.':
        'Our Earth is the ultimate space object and needs to be constantly protected from the risk of a collision with an asteroid, but todays technology allows us to prevent this threat early on.',
      "V našej slnečnej sústave okrem ôsmich planét obieha aj množstvo objektov medziplanetárnej hmoty, vrátane planétk, komét, asteroidov, meteoroidov a prachových častíc, ktoré môžu krížiť dráhu Zeme a občas môže dôjsť k stretu s väčším telesom, ktoré by v zemskej atmosfére nezhorelo. Preto je nevyhnutný pokročilý systém známy ako 'Kozmický strážca'.":
        "In addition to the eight planets in our solar system, there are also a number of interplanetary objects, including asteroids, comets, asteroids, meteoroids and dust particles, orbiting the Earth, which can occasionally cross the Earth's orbit and collide with a larger body that would not burn up in the Earth's atmosphere. That's why an advanced system known as a 'Cosmic Sentinel' is essential.",
      'Kozmický strážca má 3 hlavné úlohy': 'The Space Guardian has 3 main roles',
      Monitorovať: 'Monitor',
      Analyzovať: 'Analyse',
      Konať: 'Act',
      'V súčasnosti monitoruje nočnú oblohu niekoľko teleskopov, medzi ktoré patria napríklad Pan-STARRS na Havaji (USA), Catalina Sky Survey v Arizone (USA) a NASA misia NEOWISE. Na Slovensku sa k týmto pozorovaniam pridávajú observatóriá AGO Modra a Skalnaté pleso. Ich hlavným cieľom je nepretržite vyhľadávať nové objekty medziplanetárnej hmoty a následne sledovať ich pohyb v priestore, študovať ich zloženie a pozorovať zmeny v jasnosti a veľkosti.':
        "Several telescopes are currently monitoring the night sky, including Pan-STARRS in Hawaii (USA), the Catalina Sky Survey in Arizona (USA) and NASA's NEOWISE mission. In Slovakia, the AGO Modra and Skalnate pleso observatories join these observations. Their main goal is to continuously search for new objects of interplanetary matter and then track their movement in space, study their composition and observe changes in brightness and size.",
      'Všetky namerané dáta sa musia spracovať a pomocou výpočtov získať aktuálne dráhy a základné fyzikálne parametre jednotlivých objektov. Každý nový objekt je priradený katalógové označenie a vďaka presným výpočtom vieme určiť jeho polohu v najbližších rokoch a zistit, či predstavuje potenciálne riziko pre našu Zem.':
        'All measured data must be processed and calculations used to obtain the actual trajectories and basic physical parameters of individual objects. Each new object is assigned a catalogue designation and, thanks to precise calculations, we can determine its position in the next few years and find out if it poses a potential risk to our Earth.',
      'Ak by výpočty ukázali, že niektorý z objektov je pre nás rizikový (s priemerom viac ako 140 m a schopný sa priblížiť k Zemi na vzdialenosť iba niekoľko miliónov km), je nevyhnutné venovať mu veľkú pozornosť a v prípade potreby konať. To znamená, že je potrebné prijať opatrenia, ktoré zmenia jeho dráhu natoľko, aby už nepredstavovalo riziko pre našu Zem.':
        'If calculations show that an object is a risk to us (more than 140 m in diameter and capable of approaching Earth at a distance of only a few million km), it is essential to pay close attention to it and take action if necessary. This means taking action to change its trajectory so that it no longer poses a risk to our Earth.',
      'Príklad z praxe': 'Example from practice',
      'Zdá sa to celé ako sci-fi? Vôbec nie! Toto nie je fikcia. Celý tento proces už bol úspešne otestovaný prostredníctvom misie DART.':
        'Does it all seem like science fiction? Not at all! This is not fiction. The whole process has already been successfully tested by the DART mission.',
      'Vďaka presným pozorovaniam a následným výpočtom bol stanovený cieľ misie – asteroid Dimorphos s priemerom 150 m. Tento asteroid pre Zem nepredstavoval nebezpečenstvo, ale obiehal okolo iného asteroidu, Didymos, ktorý mal priemer 760 m. Preto bol ideálnym kandidátom na otestovanie myšlienky odklonenia dráhy asteroidu. Minulý rok v septembri (26. 9. 2022) hlavná sonda misie DART narazila do asteroidu Dimorphos a skrátila jeho obežnú dobu okolo väčšieho asteroidu o 33 minút! Budúci rok (2024) plánuje ESA misia HERA detailne preskúmať vzniknutý impaktný kráter a získať tak dôležité údaje, ktoré nám umožnia využiť túto technológiu v prípade potreby.':
        'Thanks to precise observations and subsequent calculations, the missions target - the 150 m diameter asteroid Dimorphos - has been determined. This asteroid did not pose a danger to Earth, but it was orbiting another asteroid, Didymos, which was 760 m in diameter. It was therefore an ideal candidate to test the idea of diverting the asteroids orbit. Last September (Sept. 26, 2022), the DART missions main spacecraft slammed into the asteroid Dimorphos, shortening its orbital time around the larger asteroid by 33 minutes! Next year (2024), ESAs HERA mission plans to study the resulting impact crater in detail, providing important data that will allow us to use this technology when needed.',
      'Medziplanetárnym objektom sa odborne venujú experti aj na Slovensku. Astronómovia na Fakulte matematiky, fyziky a informatiky Univerzity Komenského v Bratislave a tiež na Astronomickom ústave, SAV, v.v.i. pozorujú a skúmajú širokú škálu týchto objektov. Vďaka ich práci sa môžeme na Zemi cítiť bezpečnejšie.':
        'Interplanetary objects are also dealt with professionally by experts in Slovakia. Astronomers at the Faculty of Mathematics, Physics and Informatics of Comenius University in Bratislava and also at the Astronomical Institute, SAS, v.v.i. observe and study a wide range of these objects. Thanks to their work, we can feel safer on Earth.',
      'Slovensko sa stalo pridruženým členom Európskej vesmírnej agentúry (ESA) v roku 2022. Týmto krokom sme získali možnosť aktívne spolupracovať s ďalšími európskymi krajinami na úsilí chrániť našu Zem pred hrozbami z vesmíru.':
        'Slovakia became an associate member of the European Space Agency (ESA) in 2022, giving us the opportunity to actively cooperate with other European countries in efforts to protect our Earth from threats from space.',
      Zaujímavosť: 'Interesting fact',
      'Vedel si, že sa podarilo ku jednej kométe nie len doletieť, ale dokonca na nej aj úspešne pristáť? Bolo to v roku 2014, keď pristávací modul ESA misie Rosetta pristál na kométe 67P/Churyumov-Gerasimenko. Na časti procesora, ktorý zabezpečoval komunikáciu medzi sondou a pristávacím modulom pracovali aj experti z Košíc. Konkrétne v SPACE::LABe (na Oddelení kozmickej fyziky, Ústav experimentálnej fyziky SAV, v.v.i.), kde sa vývoju a konštrukcii prístrojov pre vesmírne misie venujú už desaťročia.':
        "Did you know that a comet has not only been flown to, but even successfully landed on? That was in 2014, when the Rosetta lander of ESA's Rosetta mission landed on comet 67P/Churyumov-Gerasimenko. Experts from Košice worked on the part of the processor that provided communication between the lander and the probe. Specifically at SPACE::LABe (at the Department of Space Physics, Institute of Experimental Physics of the Slovak Academy of Sciences, v.v.i.), where they have been developing and constructing instruments for space missions for decades.",
      'Prečítaj si viac o celosvetovom úsilí planetárnej obrany': 'Read more about global planetary defence efforts',
      'Autorské práva': 'Copyright',
      Licencia: 'License',
      'Funkcionalita aplikácie': 'Application features',
      'Aplikácia pozostáva z dvoch módov': 'The application consists of two modes',
      Vedecký: 'Scientific',
      Experimentálny: 'Experimental',
      'Vedecký mód': 'Scientific mode',
      'V tomto móde si môžeš prezerať slnečnú sústavu a asteroidy pohybujúce sa v nej. Poslúžia ti na to tieto funkcie':
        'In this mode, you can view the solar system and the asteroids moving through it. The following features will help you to do this',
      'Kliknutie na planétu / asteroid a detailný pohľad na vybraný objekt':
        'Click on a planet / asteroid and get a detailed view of the selected object',
      'Listovanie vlastností vybraného objektu (váha, objem, počet satelitov, ...)':
        'Listing the properties of the selected object (weight, volume, number of satellites, ...)',
      'Časová os ponúkajúca výber z parametrov: počiatočný dátum, konečný dátum, veľkosť jedného kroku a aktuálny dátum':
        'Timeline offering a choice of parameters: start date, end date, one step size and current date',
      'Filtre v ľavom panely slúžiace na výber zobrazených objektov alebo zobrazenie histórie prezeraných objektov':
        'Filters in the left panel for choosing which objects will render or browsing history of viewed objects',
      'Nastavenia s možnosťou zmeny jazyka v pravom paneli': 'Settings with the option to change the language in the right panel',
      'Experimentálny mód': 'Experimental mode',
      'Tento mód spustíš prepnutím tlačidla v hornej lište aplikácie.':
        'You can start this mode by toggling the button in the top bar of the app.',
      'V tomto móde si môžeš vyskúšať rolu vedca, ktorý musí odstrániť potenciálnu hrozbu pre našu planétu Zem v podobe asteroidu približujúceho sa nášho orbitu. Tvojou úlohou bude včas spustiť obrannú misiu a pomocou satelitu DART ':
        'In this mode, you can take on the role of a scientist who must eliminate a potential threat to our planet Earth in the form of an asteroid approaching our orbit. Your mission will be to launch a defensive mission in time and use a satellite DART ',
      'spomaliť asteroid tým, že ho zasiahneš. POZOR: čas nie je na tvojej strane, preto jednaj rýchlo. Misiu odštartuješ po rozkliknutí detailného pohľadu asteroidu a následovným kliknutím na požadované tlačidlo.':
        'slow down the asteroid by hitting it. WARNING: time is not on your side, so act fast. Start the mission by clicking on the close-up view of the asteroid and then clicking on the desired button.',
      'Veľa šťastia Kozmický Strážca!': 'Good luck Space Guardian!',
      'História Kliknutí': 'Clicked History',
      Prázdne: 'Empty',
      'Top 15 asteroidov': 'Top 15 asteroids',
      Tvojím: 'Your',
      cieľom: 'goal',
      'je zachrániť našu planétu': 'is to save our planet',
      'spomalením potenciálne nebezpečného asteroidu priblížujúceho sa k nej':
        'by destroing potentially dangerous asteroid approaching it',
      'Po kliknutí na': 'After clicking on',
      'môžeš naplánovať': 'you can plan',
      misiu: 'mission',
      'ktorá spomalí asteroid a tým zachráni našu planétu od potenciálnej zrážky':
        'that will slow the asteroid and save our planet from potential collision',
      'Veľa šťastia': 'Good luck',
      'Kozmický Strážca': 'Space Guardian',
      VAROVANIE: 'WARNING',
      'Zem bola zasiahnuta': 'Earth has been hit',
      'Došlo k zrážke s asteroidom': 'A collision with asteroid has occurred',
      Objekt: 'Object',
      'Skúsiť znova': 'Try again',
      'ZAČAŤ MISIU': 'START MISSION',
      dní: 'days',
      'Odhadovaný čas': 'Estimated time',
      'Praješ si započať obrannú misiu': 'Do you wish to start defense mission',
      ŠTART: 'START',
      ZRUŠIŤ: 'ABORT',
      GRATULUJEM: 'CONGRATULATIONS',
      'Úspešne si spomalil asteroid': 'Successfully slowed asteroid',
      'Zachránil si našu planétu': 'You saved our planet',
      Odísť: 'Quit',
      Slnko: 'Sun',
      'Orbity top asteroidov': 'Top asteroids orbits',
      'Zdroj dát a vizualizácia': 'Data source and visualisation',
      'Pozičné dáta a atribúty objektov': 'Position data and object attributes',
      'Textúry planét': 'Planet textures',
      'Textúra asteroidu a satelitu DART': 'Asteroid and DART satellite texture',
      'Vizualizácia nemusí zodpovedať realite kvôli obmedzeniam 3D zobrazenia.':
        'The visualisation may not correspond to reality due to the limitations of the 3D display.',
      'Atribúty planét': 'Planet attributes',
      'Všetky asteroidy - príliš veľké časové rozpätie': 'All asteroids - too big time range',
      'Začiatok misie': 'Mission start',
      'Koniec misie': 'Mission end',
      'Praješ si začať obrannú misiu': 'Start defense mission',
      'Vyskúšaj si ďalšie scénare pomocou zmeny času na časovej osi a pozoruj, čo sa stane s našou planétou, ak by naši vedci neskoro zareagovali.':
        'Try out other scenarios by changing the time on the timeline and see what happens to our planet if our scientists are too late to react.',
      Potvrdiť: 'Confirm',
      Znova: 'Again',
      NEBEZPEČENSTVO: 'DANGER',
      'Hudba v pozadí': 'Background music',
      Zvuk: 'Sound',
      'Vylepšenia viditeľnosti': 'Visibility enhancements',
      'Zväčšenie objektov': 'Larger objects',
      'Ovládanie myšou': 'Mouse movement',
      'Pravé tlačidlo a ťahanie - Kliknutím a ťahaním myši môžete interagovať s objektmi v scéne.':
        'Right click and drag - Click and drag the mouse to interact with objects in the scene.',
      'Ľavé tlačidlo a ťahanie - Kliknutím a ťahaním myši môžete meniť stred rotácie.':
        'Left click and drag - Click and drag the mouse to change the centre of rotation.',
      'Koliesko myši - Pomocou kolieska myši môžete priblížiť alebo oddialiť vašu perspektívu scény.':
        'Mouse wheel - Use the mouse wheel to zoom in or out on your perspective of the scene.',
      'Príliš malý displej, skús ho otočiť na výšku pre info o objekte.':
        'Too small display, try rotating it to portrait for object info.',
      'HĽADÁM MANÉVRE': 'SEARCHING MANEUVERS',
      KOLÍZIE: 'COLLISIONS',
      ODZNAČ: 'UNSELECT',
      VYCENTRUJ: 'CENTER',
      Vycentruj: 'Center',
      Odznač: 'Unselect',
      'Vyber manéver!': 'Choose maneuver!',
      'Manévre sú dostupné': 'Maneuvers are available',
      'ID KOLÍZIE': 'COLLISION ID',
      'OBJEKT A': 'OBJECT A',
      'OBJEKT B': 'OBJECT B',
      VYTVORENÉ: 'CREATED ON',
      STRET: 'IMPACT ON',
      PRAVDEPODOBNOSŤ: 'PROBABILITY',
      ORBITA: 'ORBIT',
      Orbita: 'Orbit',
      'Aplikujem manéver': 'Applying maneuver',
      'Naozaj chcete používať manéver? Po použití vybraného manévru nasleduje automatická akcia pohybu.':
        'Do you really want to use maneuver? After applying the selected maneuver, an automatic move action follows.',
      POUŽIŤ: 'APPLY',
      ZRUŠIŤ: 'CANCEL',
      'Najprv odznač manéver!': 'Unselect satellite first!',
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
