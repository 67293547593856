import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import backgroundVideo from '../videos/scp-video2.mp4'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Header from '../components/Header'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { urlLogin } from '../util/States'
import { useDispatch, useSelector } from 'react-redux'
import isLoggedIn from '../redux/isLoggedIn'
import { checkLoggedIn } from '../redux/isLoggedIn'
import { setUser } from '../redux/preferencesSlice'
import './NoRights.css'
import './LogIn.css'

function LogIn() {
  const navigate = useNavigate()

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn)

  useEffect(() => {
    dispatch(checkLoggedIn())
  }, [dispatch])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: ValidationSchema,
    onSubmit: async (e) => {
      const response = await fetch(urlLogin + 'login', {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: v.email,
          email: v.email,
          password: v.password,
        }),
      })

      if (response.status === 200) {
        navigate('/home') // Navigate to the home page or dashboard
      } else {
        alert('Something went wrong')
      }
    },
  })

  const e = formik.errors
  const v = formik.values
  const t = formik.touched

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      formik.handleSubmit()
    }
  }

  return (
    <>
      {windowWidth > 800 && <video className="video" src={backgroundVideo} autoPlay loop muted disablePictureInPicture />}
      <Header windowWidth={windowWidth} />
      <div className="login">
        <div className="login-container">
          <div className="login-container-box">
            {!isLoggedIn ? (
              <>
                <h5 className="login-container-box-title">
                  Log in to continue <div className="login-container-box-title bluedot">.</div>
                </h5>
                {/*
            <div className="login-container-box-subtitle">
              Not registered yet? <Link className="login-container-box-subtitle-link" to={'/signup'}>Sign up</Link>
  </div>
          */}
                <div className="login-container-box-inputs">
                  <div className="login-container-box-inputs-row">
                    <TextField
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={e.email && t.email}
                      helperText={e.email && t.email ? e.email : null}
                      name="email"
                      fullWidth
                      size="normal"
                      value={v.email}
                      type="email"
                      className="login-container-box-inputs-input Email"
                      label="Email"
                      variant="outlined"
                    />
                  </div>
                  <div className="login-container-box-inputs-row">
                    <TextField
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={e.password && t.password}
                      helperText={e.password && t.password ? e.password : null}
                      name="password"
                      fullWidth
                      size="normal"
                      value={v.password}
                      onKeyDown={handleKeyDown}
                      className="login-container-box-inputs-input Password"
                      type="password"
                      label="Passsword"
                      variant="outlined"
                    />
                  </div>
                  <div className="login-container-box-inputs-row Register">
                    <Button
                      className="login-container-box-inputs-button Register"
                      variant="contained"
                      size="large"
                      onClick={formik.handleSubmit}
                    >
                      Log in
                    </Button>
                    <Button
                      className="login-container-box-inputs-button Register"
                      variant="outlined"
                      size="large"
                      onClick={() => navigate('/toolbox')}
                    >
                      GUEST
                    </Button>
                  </div>
                  {/* <div className="login-container-box-guest" onClick={() => navigate('/viewer')}>
                    Log in as guest ?
                  </div> */}
                </div>
              </>
            ) : (
              <h5 className="login-container-box-title">
                You're already logged in <div className="login-container-box-title bluedot">.</div>
              </h5>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default LogIn
